<template>
  <div class="expande-horizontal">
    <v-flex xs12>
      <div class="expande-horizontal wrap">
        <v-flex xs12>
          <div class="expande-horizontal">
            <div class="expande-horizontal centraliza">
              <div class="expande-horizontal column title-item">
                <span style="font-size: 16pt;" class="fonte-bold"
                  >Dados Pessoais</span
                >
                <span class="fonte grey--text">Veja abaixo</span>
              </div>
            </div>
          </div>
        </v-flex>
        <v-form ref="usuario">
          <div class="expande-horizontal" style="flex-wrap: wrap">
            <!-- <v-flex xs12 md6>
              <v-text-field
                dense
                readonly
                filled
                :color="$theme.primary"
                label="Endereço da Carteira"
                v-model="getLoggedUser._id"
              ></v-text-field>
            </v-flex> -->

            <v-flex xs12 md6>
              <v-text-field
                dense
                filled
                :color="$theme.primary"
                label="Nome"
                v-model="getLoggedUser.name"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md4>
              <v-text-field
                dense
                filled
                readonly
                :color="$theme.primary"
                label="Email"
                v-model="getLoggedUser.email"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                dense
                disabled
                filled
                :color="$theme.primary"
                label="Nome de usuário"
                v-model="getLoggedUser.nickname"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                dense
                filled
                readonly
                :color="$theme.primary"
                v-mask="['###.###.###-##']"
                label="Cpf"
                v-model="getLoggedUser.cpf"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md4>
              <v-text-field
                dense
                filled
                :color="$theme.primary"
                label="Celular"
                v-model="getLoggedUser.phone"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal">
                <div class="expande-horizontal centraliza">
                  <div class="expande-horizontal column title-item">
                    <span style="font-size: 16pt;" class="fonte-bold"
                      >Bonificações</span
                    >
                    <span class="fonte grey--text"
                      >Veja as porcentagens abaixo</span
                    >
                  </div>
                </div>
              </div>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal">
                <v-flex xs12>
                  <v-list three-line color="transparent">
                    <v-list-item class="px-0 mx-0">
                      <v-avatar color="#333" class="mr-3">
                        <v-icon color="yellow darken-2">mdi-storefront</v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="fonte-bold" style="font-size: 14pt;">
                            Estabelecimentos
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Ganho de 1% sobre o faturamento de cada estabelecimento cadastrado
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item class="px-0 mx-0" v-for="gain in gain_percents" :key="gain.title">
                      <v-avatar color="#333" class="mr-3">
                        <v-icon :color=" gain.title == 'Black' ? 'yellow darken-2' : '#fff'">mdi-trophy-award</v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="fonte-bold" style="font-size: 14pt;">
                            {{ gain.title }}
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Ganho de {{ gain.value }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ gain.sponsoreds }} indicados necessários
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="fonte-bold fonteMini">1.0.17</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Versão do app
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </div>
            </v-flex>
            <!--       
            <v-flex xs12  md4>
              <v-text-field  dense filled :color="$theme.primary" label="Tamanho do calçado" v-model="getLoggedUser.tamanho_tenis" ></v-text-field>
            </v-flex>
            
            <v-flex xs12  md4>
              <v-text-field  dense filled :color="$theme.primary" label="sexo" v-model="getLoggedUser.sexo" ></v-text-field>
            </v-flex>
            
            <v-flex xs12  md4>
              <v-text-field  dense filled :color="$theme.primary" label="Medidas" v-model="getLoggedUser.medidas" ></v-text-field>
            </v-flex>
            
            <v-flex xs12  md4>
              <v-text-field  dense filled :color="$theme.primary" label="Tamanho da veste" v-model="getLoggedUser.tamanho_roupa" ></v-text-field>
            </v-flex>
             -->
            <v-flex class="mt-6" xs12>
              <v-btn @click="updateProfile" color="black"  x-large dark>
                salvar</v-btn
              >
            </v-flex>
            <!-- <div class="expande-horizonta">1.0.17</div> -->
          </div>
        </v-form>
      </div>
    </v-flex>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      gain_percents: [
        {
          title: "Personal",
          value: "1%",
          sponsoreds: 6
        },
        {
          title: "Gold",
          value: "1%",
          sponsoreds: 36
        },
        {
          title: "Platinum",
          value: "2%",
          sponsoreds: 216
        },
        {
          title: "Black",
          value: "2%",
          sponsoreds: 1296
        }
      ]
    }
  },
  methods: {
    ...mapActions(["updateProfile"])
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  }
};
</script>
